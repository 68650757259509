<template>
  <div>
    <section>
      <div class="flex">
        <h2 class="no-border">Coin Price Multipliers</h2>
          <Button
            v-if="modifiedSettings"
            class="reset-all"
            :eventName="constant.SETTING_RESET"
            @clicked="clicked($event)"
          >
            Reset All
          </Button>
          <span
            v-else
            class="reset-all-disabled"
          >
            Reset All
          </span>
      </div>
      <AppHeaderSettingsConfigsItem 
        :constant="constant"
        :setting="configs.settings[1]"
        :activeNetwork="activeNetwork"
        @modalScreen="modalScreen($event)"
        @updateConfiguration="updateConfiguration($event)"
      />
      <AppHeaderSettingsConfigsItem 
        :constant="constant"
        :setting="configs.settings[2]"
        :activeNetwork="activeNetwork"
        @modalScreen="modalScreen($event)"
        @updateConfiguration="updateConfiguration($event)"
      />
    </section>
    <section>
      <h2>Max Coin Supply Mint Rate</h2>
      <AppHeaderSettingsConfigsItem 
        :constant="constant"
        :setting="configs.settings[3]"
        :activeNetwork="activeNetwork"
        @modalScreen="modalScreen($event)"
        @updateConfiguration="updateConfiguration($event)"
      />
    </section>
    <section>
      <h2>Network Collateral Rate</h2>
      <AppHeaderSettingsConfigsItem 
        :constant="constant"
        :setting="configs.settings[4]"
        :activeNetwork="activeNetwork"
        @modalScreen="modalScreen($event)"
        @updateConfiguration="updateConfiguration($event)"
      />
    </section>
  </div>
</template>
<script setup>
  import { event } from 'vue-gtag'
  import { computed } from 'vue'
  import Button from '../../../_generics/Button.vue'
  import AppHeaderSettingsConfigsItem from './AppHeaderSettingsConfigsItem.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
  })

  const constant = props.constant

  const emit = defineEmits([
    'modalScreen',
    'updateConfiguration',
    'resetConfiguration'
  ])

  const updateConfiguration = (event) => {
    emit('updateConfiguration', event)
  }

  const modifiedSettings = computed(() => {
    const result = props.configs.settings.find((setting) => setting.set != setting.default)
    return result ? true : false
  })

  const clicked = (event) => {
    const settings = {
      networkId: props.activeNetwork.id,
      networkName: props.activeNetwork.name,
      type: constant.SETTINGS_CONFIGURATIONS
    }
    trackEvent('reset_configurations', 'reset_configurations_settings', 'reset_configurations_click')
    emit('resetConfiguration', settings)
  }

  const modalScreen = (event) => {
    emit('modalScreen', event)
  }

  const trackEvent = (eventType, eventCategory, eventLabel) => {
    event(eventType, {
      'event_category': eventCategory,
      'event_label': eventLabel
    })
  }
</script>
<style lang="scss" scoped>
  @import '../../../../assets/scss/_variables.scss';

  .flex {
    align-items: flex-end;
    border-bottom: thin solid $GREY-XXXLIGHT;

    .reset-all, .reset-all-disabled {
      font-size: $FONT-XXSMALL;
      margin: 0 15px 4px 0;
      color: $PURPLE;
    }
    .reset-all-disabled {
      color: $GREY-XLIGHT;
      padding: 0 6px;
      margin-bottom: 5px;
    }
    .reset-all {
      display: block;
    }
  }

  h2 {
    text-align: left;
    margin: 0px 5px 5px 20px;
    font-size: $FONT-XSMALL;
    font-weight: 600;
    color: $BLACK;

    &:first-Child {
      margin-top: 0;
    }
  }
  .title-xxsmall {
    color: $BLACK;
    text-align: center;
    padding-left: 5px;
  } 
  section {
    border-top: thin solid $WHITE;

    &:first-child {
      border: none;
    }

    h2 {
      padding: 30px 5px 5px 20px;
      margin: 0;
      border-bottom: thin solid $GREY-XXXLIGHT;

      &:first-child {
        padding: 10px 5px 5px 20px;
      }

      &.no-border {
        border: none;
      }
    }
  }
</style>