<template>
  <header>
    <Transition name="subnav">
      <AppHeaderInformation 
        v-if="infoScreen.isActive"
        :constant="constant"
        :activeNetwork="activeNetwork"
        :configs="configs"
        :appSpecs="appSpecs"
        :screensCarousel="screensCarousel"
        :informationScreens="informationScreens"
        @updateScreenInformation="updateScreenInformation($event)"
      />
    </Transition>

    <Transition name="subnav">
      <AppHeaderSettings 
        v-if="settingsScreen.isActive"
        :constant="constant"
        :activeNetwork="activeNetwork"
        :configs="configs"
        :localStorageAgreed="localStorageAgreed"
        :appSpecs="appSpecs"
        :toggleSettingsButtons="toggleSettingsButtons"
        @modalScreen="modalScreen($event)"
        @updateConfiguration="updateConfiguration($event)"
        @resetConfiguration="resetConfiguration($event)"
        @updateScreenSettings="updateScreenSettings($event)"
        @resetAllAppData="resetAppData($event)"
      />
    </Transition>
    
    <Transition name="drop-shadow">
      <div 
        v-if="settingsScreen.isActive || infoScreen.isActive"
        class="drop-shadow"
      >
     </div>
    </Transition>

    <div class="logo mobile">
      <Button
        :eventName="constant.GET_STARTED"
        @clicked="clicked($event)"
      >
        <SimFassetLogoAlt />
        
      </Button>
    </div>

    <SubNavigation 
      :constant="constant"
      :activeNetwork="props.activeNetwork"
      :isActiveSettingsSubNav="settingsScreen.isActive"
      :isActiveInfoSubNav="infoScreen.isActive"
      @nextScreen="clicked($event)"
      @activateScreenSubNav="activateScreenSubNav($event)"
    />
  </header>
</template>
<script setup>
  import Button from '../../_generics/Button.vue'
  import SimFassetLogoAlt from '../../_icons/SimFassetLogoAlt.vue'
  import SubNavigation from '../AppHeader/AppHeaderSubNavigation.vue'
  import AppHeaderSettings from './AppHeaderSettings/AppHeaderSettings.vue'
  import AppHeaderInformation from './AppHeaderInformation/AppHeaderInformation.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    },
    localStorageAgreed: {
      type: Boolean,
      default: false
    },
    settingsScreen: {
      type: Object,
      default: () => {}
    },
    infoScreen: {
      type: Object,
      default: () => {}
    },
    informationScreens: {
      type: Array,
      default: () => []
    },
    toggleSettingsButtons: {
      type: Array,
      default: () => []
    },
    appSpecs: {
      type: Object,
      default: () => {}
    },
    screensCarousel: {
      type: Array,
      default: () => []
    }
  })
  const constant = props.constant

  const emit = defineEmits([
    'nextScreen',
    'modalScreen',
    'activateScreenSubNav',
    'updateConfiguration',
    'resetConfiguration',
    'updateScreenSettings',
    'updateScreenInformation',
    'resetAllAppData',
  ])

  const clicked = (event = null) => {
    emit('nextScreen', event)
  }

  const activateScreenSubNav = (event) => {
    emit('activateScreenSubNav', event)
  }

  const updateConfiguration = (event) => {
    emit('updateConfiguration', event)
  }

  const resetConfiguration = (event) => {
    emit('resetConfiguration', event)
  }

  const updateScreenSettings = (event) => {
    emit('updateScreenSettings', event)
  }

  const updateScreenInformation = (event) => {
    emit('updateScreenInformation', event)
  }

  const resetAppData = (event) => {
    emit('resetAllAppData', event)
  }

  const modalScreen = (event) => {
    emit('modalScreen', event)
  }
</script>
<style lang="scss" scoped>
  @import '../../../assets/scss/_variables.scss';
  @import '../../../assets/scss/mixins/layout.scss';

  .logo {
    position: relative;
    margin: 0 auto;
    padding: 2px 0 0 0;
    background-color: $WHITE;
  }

  .drop-shadow {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 auto;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    padding: 5px 0 0 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    box-shadow: 0px 5px 7px -4px rgba(0,0,0,0.24);
    transition: opacity 3.0s ease-in-out;
  }

  .subnav-enter-from {
    top: -1000px;
  }
  .subnav-enter-to {
    top: calc($HEADER-HEIGHT - 3px);

    @include breakpoint(lg) {
      top: calc($HEADER-HEIGHT-DESKTOP - 3px);
    }
    @include breakpoint(xl) {
      top: calc($HEADER-HEIGHT-DESKTOP - 3px);
    }
    @include breakpoint(xxl) {
      top: calc($HEADER-HEIGHT-DESKTOP - 3px);
    }
    @include breakpoint(xxxl) {
      top: calc($HEADER-HEIGHT-DESKTOP - 3px);
    }
  }
  .subnav-enter-active {
    transition: top 0.2s ease-out;
  }

  .subnav-leave-from {
    top: calc($HEADER-HEIGHT - 3px);

    @include breakpoint(lg) {
      top: calc($HEADER-HEIGHT-DESKTOP - 3px);
    }
    @include breakpoint(xl) {
      top: calc($HEADER-HEIGHT-DESKTOP - 3px);
    }
    @include breakpoint(xxl) {
      top: calc($HEADER-HEIGHT-DESKTOP - 3px);
    }
    @include breakpoint(xxxl) {
      top: calc($HEADER-HEIGHT-DESKTOP - 3px);
    }
  }
  .subnav-leave-to {
    top: -1000px;
  }
  .subnav-leave-active{
    transition: top 0.2s ease-in;
  }

  .drop-shadow-enter-from {
    opacity: 0;
  }
  .drop-shadow-enter-to {
    opacity: 1;
  }
  .drop-shadow-enter-active {
    transition: all 0.25s ease-in-out;
  }
  
  .drop-shadow-leave-from {
    opacity: 1;
  }
  .drop-shadow-leave-to {
    opacity: 0;
  }
  .drop-shadow-leave-active{
    transition: all .25s ease-in-out;
  }
</style>