<template>
  <GetStarted 
    :constant="constant"
    :activeNetwork="networkStore.getActiveNetwork"
    :screensCarousel="screenManager.getScreensCarousel"
    :appSpecs="configurationStore.getApplicationSpecifications"
    @nextScreen="nextPrimaryScreen($event)"
  />
</template>
<script setup>
  import { ref, inject } from 'vue'
  import { useRouter } from 'vue-router'
  import { useScreenManagementStore } from '../stores/ScreenManagementStore.js'
  import { useNetworkStore } from '../stores/NetworkStore.js'
  import GetStarted from '../components/GetStarted/GetStarted.vue'
  import { useConfigurationStore } from '../stores/ConfigurationStore.js'

  const constant = inject('constant')
  const router = useRouter()

  const screenManager = useScreenManagementStore()
  const networkStore = useNetworkStore()
  const configurationStore = useConfigurationStore()

  const nextPrimaryScreen = (event) => {
    screenManager.deactivateScreensSubNav()
    router.push({path : `/${event}`})
  }
</script>