<template>
  <section class="settings">
    <div class="inner">
      <div class="scroll">
        <h1>Settings</h1>

        <!-- <div class="btn-toggle-container mb-60">
          <ButtonToggle 
            :constant="constant"
            :toggleButtons="toggleSettingsButtons"
            :containerWidth="290"
            :btnWidth="143"
            @btnToggleClicked="changeSettingsScreen($event)"
          />
        </div> -->
        
        <AppHeaderSettingsExcludes
          v-if="toggleSettingsButtons[0].isActive"
          :constant="constant"
          :excludes="configs.topHolders"
          :activeNetwork="activeNetwork"
          @updateConfiguration="updateConfiguration($event)"
          @resetConfiguration="resetConfiguration($event)"
        />
        <AppHeaderSettingsConfigs
          v-else
          :constant="constant"
          :configs="configs"
          :activeNetwork="activeNetwork"
          @modalScreen="modalScreen($event)"
          @updateConfiguration="updateConfiguration($event)"
          @resetConfiguration="resetConfiguration($event)"
        />

      </div>
      <div class="border-top">
        <Button 
          class="clear-data-btn" 
          :eventName="constant.RESET_APP_DATA"
          :disabled="!localStorageAgreed"
          @clicked="clicked($event)"
        >
          Reset App Data
        </Button>
      </div>
      <AppFooter 
        :classes="classes"
        :appSpecs="appSpecs"
      />
    </div>
  </section>
</template>
<script setup>
  import { computed } from 'vue'
  import { event } from "vue-gtag"
  import Button from '../../../_generics/Button.vue'
  import AppFooter from '../../AppFooter/AppFooter.vue'
  import ButtonToggle from '../../../_generics/ButtonToggle.vue'
  import AppHeaderSettingsExcludes from '../AppHeaderSettings/AppHeaderSettingsExcludes.vue'
  import AppHeaderSettingsConfigs from '../AppHeaderSettings/AppHeaderSettingsConfigs.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    },
    appSpecs: {
      type: Object,
      default: () => {}
    },
    localStorageAgreed: {
      type: Boolean,
      default: false
    },
    toggleSettingsButtons: {
      type: Array,
      default: () => []
    },
  })
  
  const emit = defineEmits([
    'modalScreen',
    'updateScreenSettings',
    'updateConfiguration',
    'resetConfiguration',
    'resetAllAppData'
  ])
  const constant = props.constant

  const classes = computed(() => {
    return {
      'footer' : true
    }
  })
  
  // const changeSettingsScreen = (event) => {
    
  //   const screenSettings = {
  //     networkId: props.activeNetwork.id,
  //     id: event === constant.SETTINGS_EXCLUDES ? 1 : 2,
  //     name: event,
  //     isActive: true
  //   }

  //   emit('updateScreenSettings', screenSettings)
  // }

  const updateConfiguration = (event) => {
    emit('updateConfiguration', event)
  }

  const resetConfiguration = (event) => {
    emit('resetConfiguration', event)
  }

  const clicked = (event) => {
    trackEvent('reset_all_app_data', 'reset_app_data', 'reset_click')
    emit('resetAllAppData', event)
  }

  const modalScreen = (event) => {
    emit('modalScreen', event)
  }

  const trackEvent = (eventType, eventCategory, eventLabel) => {
    event(eventType, {
      'event_category': eventCategory,
      'event_label': eventLabel
    })
  }
</script>
<style lang="scss" scoped>
  @import '../../../../assets/scss/_variables.scss';

  .clear-data-btn {
    background-color: $PURPLE;
    padding: 10px;
    border-radius: 5px;
    border: thin solid $GREY-LIGHT;
    margin: 60px 0 50px 0;
    color: $WHITE;
    font-size: $FONT-SMALL;

    &:disabled {
      background-color: $GREY-XXLIGHT;
      border: thin solid $GREY-XLIGHT;
      color: $GREY-LIGHT;
      pointer-events: none;
    }
  }

  .border-top {
    border-top: thin solid $WHITE;
  }
</style>