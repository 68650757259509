<template>
 <input 
  type="checkbox"
  :class="classes"
  v-model="toggledValue"
/>
</template>
<script setup>
  import { computed } from 'vue'

  const props = defineProps({
    id: {
      type: Number,
      default: 0
    },
    classes: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
  })

  const emit = defineEmits([
    'updateToggleSwitch'
  ])

  const toggledValue = computed({
    get: () => {
      return props.modelValue
    },
    set: (value) => {
      const checkboxEvent = {
        id: props.id,
        networkName: props.activeNetwork.name,
        checked: value
      }
      emit('updateToggleSwitch', checkboxEvent)
    }
  })
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    background-color: $GREY-LIGHT;
    width: 53px;
    height: 27px;
    border-radius: 20px;
    transition: 0.15s ease all;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      height: 27px;
      width: 27px;
      left: 0;
      border-radius: 20px;
      background-color: $WHITE;
      transform: scale(0.9);
      transition: 0.15s ease all;
    }
  }

  input[type="checkbox"]:not(:checked) {

    &::before{
      background-color: $WHITE;
    }
  }

  input[type="checkbox"]:checked {
    background-color: $PURPLE;

    &::before {
      left: 26px;
    }
  }
</style>