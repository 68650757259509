<template>
  <div
    class="container"
  >
    <div class="flex">
      <PieChart />
      <h2>Network Collateral</h2>
    </div>
    <div>
      <h3>Details</h3>

      <table>
        <tbody>

          <tr>
            <td class="txt-left txt-bold"
              :class="classes"
            >
              {{ activeNetwork.ticker }} Collateral TVL - {{ collateralRateNative() }}%
            </td>
            <td class="txt-right txt-bold"
              :class="classes"
            >
              ${{ NATIVEcollateral }}
            </td>
          </tr>

          <tr>
            <td class="txt-left"><span class="txt-uppercase">Stable</span> Collateral TVL - {{ collateralRateUSD() }}%</td>
            <td class="txt-right">${{ USDcollateral }}</td>
          </tr>

          <tr
            v-if="collateralFAssetIsActive"
          >
            <td class="txt-left">
              <span class="txt-uppercase">BTC/ETH</span> Collateral TVL - {{ collateralRateFAsset() }}%
            </td>
            <td class="txt-right">${{ FASSETcollateral }}</td>
          </tr>

          <tr>
            <td class="txt-left txt-bold">Total Collateral TVL</td>
            <td class="txt-right txt-bold">${{ totalCollateralRequired }}</td>
          </tr>
          
        </tbody>
      </table>

      <Button 
        class="manage-btn" 
        :eventName="constant.MINTING"
        @clicked="clicked($event)"
      >
        Manage
      </Button>
    </div>
  
  </div>
</template>
<script setup>
  import { computed } from 'vue'
  import PieChart from '../_icons/PieChart.vue'
  import Button from '../_generics/Button.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    isNativeCollateral: {
      type: Boolean,
      default: false
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    totalCollateralRequired: {
      type: String,
      default: 0
    },
    USDcollateral: {
      type: String,
      default: ''
    },
    NATIVEcollateral: {
      type: String,
      default: ''
    },
    FASSETcollateral: {
      type: String,
      default: ''
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const constant = props.constant

  const emit = defineEmits([
    'collateralScreen'
  ])

  const classes = computed(() => {
    return {
      'color-purple' : props.activeNetwork.name === constant.FLARE_NETWORK,
      'color-red' : props.activeNetwork.name === constant.SONGBIRD_NETWORK
    }
  })

  const collateralRateNative = () => {
    if(props.collateralFAssetIsActive) {
    return parseFloat(props.networkCollateralAllocation[2].set * 100).toFixed(0)
    }
    return parseFloat(props.networkCollateralAllocation[1].set * 100).toFixed(0)
  }

  const collateralRateUSD = () => {
    return parseFloat(props.networkCollateralAllocation[0].set * 100).toFixed(0)
  }

  const collateralRateFAsset = () => {
    if(props.collateralFAssetIsActive) {
      return parseFloat(props.networkCollateralAllocation[1].set *100).toFixed(0)
    }
    return 0
  }

  const clicked = (event) => {
    emit('collateralScreen', event)
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';
  
  .container {
    position: relative;
    height: 100%;
    width: 100%;
    flex-direction: column;
    color: $BLACK;
    
    .flex {
      width: 100%;
      margin-top: 10px;
      justify-content: center;

      h2 {
        font-weight: 600;
        margin:  0 0 0 10px;
      }
    }
    h3 {
      margin-top: 20px;
      font-size: $FONT-SMALL;
    }
    p {
      margin: 20px 10px;
      font-size: $FONT-SMALL;
      color: $BLACK;
    }

    table {
      font-size: $FONT-XSMALL;
      padding: 0;
      margin: 10 auto 30px auto;
      width: 70%;
      min-width: 340px;

      tr {
        line-height: $FONT-XLARGE;
        letter-spacing: 1px;
        color: $GREY-LIGHT;

        &:last-child {
           color: $BLACK;
        }
      }
    }

    .manage-btn {
      background-color: $PURPLE;
      padding: 10px;
      border-radius: 5px;
      border: thin solid $GREY-LIGHT;
      min-width: 150px;
      margin: 10px 0 30px 0;
      color: $WHITE;
      font-size: $FONT-SMALL;
    }
  }
</style>