<template>
  <!-- Two Strokes -- animation -->
  <div class="btn-container">
    <a 
      class="animated-btn"
      @click="nextScreen($event)"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span class="txt-uppercase">{{ label }}</span>
    </a>
  </div>
</template>
<script setup>

  const props = defineProps({
    label: {
      type: String,
      default: ''
    },
    event: {
      type: String,
      default: ''
    },
  })

  const emit = defineEmits([
    'nextScreen',
  ])

  const nextScreen = (event) => {
    emit('nextScreen', event)
  }

</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';

  .btn-container {
    position: relative;
    background-color: pink;
    width: 100%;
    margin: 20px 0 120px 0;

    .animated-btn {
      position: absolute;
      left: 50%;
      cursor: pointer;
      transform: translate(-50%, 0);
      color: $ORANGE;
      overflow: hidden;
      width: 60%;
      max-width: 230px;
      padding: 25px 50px;
      text-decoration: none;
      font-size: $FONT-DEFAULT-SIZE;
      font-weight: 500;
      letter-spacing: 2px;
      box-shadow: 0 20px 50px $WHITE-XLIGHT;
      background: $WHITE-XLIGHT;
      transition: background 0.25s ease-out;

      &:hover {
        background: $WHITE-MEDIUM;
        transition: all 0.25s ease-out;
      }

      span:nth-child(1), span:nth-child(2), span:nth-child(3), span:nth-child(4) {
        position: absolute;
      }

      //Top
      span:nth-child(1) {
        top: 0;
        left: 0; 
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, #0a0971, $WHITE);
        animation: animate1 1.5s linear infinite;
        animation-delay: 0s;
      }

      //Right
      span:nth-child(2) {
        top: 0;
        right: 0; 
        width: 1px;
        height: 100%;
        opacity: 0;
        background: linear-gradient(to bottom, #0a0971, $WHITE);
        animation: animate2 1.5s linear infinite;
        animation-delay: 0.75s;
      }

      //Botttom
      span:nth-child(3) {
        bottom: 0;
        left: 0; 
        width: 100%;
        height: 1px;
        background: linear-gradient(to left, #0a0971, $WHITE);
        animation: animate3 1.5s linear infinite;
        animation-delay: 0s;
      }

      //Left
      span:nth-child(4) {
        top: 0;
        left: 0; 
        width: 1px;
        height: 100%;
        opacity: 0;
        background: linear-gradient(to top, #0a0971, $WHITE);
        animation: animate4 1.5s linear infinite;
        animation-delay: 0.75s;
      }
    }
  }
</style>