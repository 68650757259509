<template>
  <section class="information">
    <div class="inner">
      <div class="scroll">
        <h1>Information</h1>

        <div class="btn-toggle-container mt-40 mb-40">
          <ButtonToggle 
            :constant="constant"
            :toggleButtons="informationScreens"
            :containerWidth="210"
            :btnWidth="103"
            @btnToggleClicked="changeScreen($event)"
          />
        </div>

        <AppHeaderInfo
          v-if="informationScreens[0].isActive"
          :constant="constant"
          :activeNetwork="activeNetwork"
          :screensCarousel="screensCarousel"
        />
        <AppHeaderFaq
          v-else
          :classes="'mt-60 mb-40'"
          :constant="constant"
          :activeNetwork="activeNetwork"
          :faqs="informationScreens[1].questions"
          @activateFaqAccordion="activateFaqAccordion($event)"
        />

        <AppFooter 
          :classes="classes"
          :appSpecs="appSpecs"
        />
      </div>
    </div>
  </section>
</template>
<script setup>
  import { computed } from 'vue'
  import { event } from 'vue-gtag'
  import AppHeaderInfo from './AppHeaderInfo.vue'
  import AppHeaderFaq from './AppHeaderFaq.vue'
  import ButtonToggle from '../../../_generics/ButtonToggle.vue'
  import AppFooter from '../../AppFooter/AppFooter'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    },
    appSpecs: {
      type: Object,
      default: () => {}
    },
    informationScreens: {
      type: Array,
      default: () => []
    },
    screensCarousel: {
      type: Array,
      default: () => []
    },
  })
  const emit = defineEmits([
    'activateFaqAccordion',
    'updateScreenInformation',
  ])

  const constant = props.constant

  const classes = computed (() => {
    return {
      'footer' : true
    }
  })

  const changeScreen = (event) => {
    
    const screenInformation = {
      networkId: props.activeNetwork.id,
      id: event === constant.INFORMATION_INFO ? 1 : 2,
      name: event,
      isActive: true,
      questionIsActiveId: null,
      questionIsActive: null
    }

    if(constant.INFORMATION_INFO === event) {
      trackEvent('information_toggle', 'information_toggle_btn', 'information_click')
    } else {
      trackEvent('faq_toggle', 'faq_toggle_btn', 'faq_click')
    }

    emit('updateScreenInformation', screenInformation)
  }

  //In Progress
  const activateFaqAccordion = (event) => {
    const screenInformation = {
      networkId: props.activeNetwork.id,
      id: 2,
      questionIsActiveId: event.id,
      questionIsActive: event.isActive
    }
    emit('updateScreenInformation', screenInformation)
  }

  const trackEvent = (eventType, eventCategory, eventLabel) => {
    event(eventType, {
      'event_category': eventCategory,
      'event_label': eventLabel
    })
  }
</script>
<style lang="scss" scoped>
  @import '../../../../assets/scss/_variables.scss';
  @import '../../../../assets/scss/mixins/layout.scss';
</style>