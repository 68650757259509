import { defineStore } from 'pinia'
import Constant from '../global/Constant.js'

export const useScreenManagementStore = defineStore('screenManagementStore', {
  state: () => ({
    screensSubNav: [
      {
        name: Constant.SETTINGS,
        id: 1,
        isActive: false,
      },
      {
        name: Constant.INFORMATION,
        id: 2,
        isActive: false,
      },
      {
        name: Constant.NETWORK,
        id: 3,
        isActive: false,
      },
      {
        name: Constant.SHARE,
        id: 4,
        isActive: false,
      },
      {
        name: Constant.COOKIE_DISCLOSURE,
        id:5,
        isActive: true
      },
    ],
    screensMintCollateral: [
      {
        id: 1,
        name: 'Mint',
        eventName: Constant.MINT_FASSETS,
        isActive: true
      },
      {
        id: 2,
        name: 'Collateral',
        eventName: Constant.COLLATERAL_BACKING,
        isActive: false
      }
    ],
    screensInformation: [
      {
        id: 1,
        name: 'About',
        eventName: Constant.INFORMATION_INFO,
        isActive: true
      },
      {
        id: 2,
        name: 'FAQ',
        eventName: Constant.INFORMATION_FAQ,
        isActive: false,
        questions: [
          {
            id: 1,
            question: 'What\'s the purpose of SimFasset?',
            answer: 'SimFasset is a tool, like a caculator, to help you determine the minimum price of the native network\'s coin price purely based on the amount of value bridged onto the network via the <a href="https://docs.flare.network/tech/fassets/" target="_blank">FAsset</a> system.',
            isActive: false
          },
          {
            id: 2,
            question: 'Can I connect SimFasset to my crypto&nbsp;wallet?',
            answer: 'At this time, SimFasset has NO ability to connect to your crypto wallet(s). Please stay safe!',
            isActive: false
          },
          {
            id: 3,
            question: 'Is there any chance to lose my coins&nbsp;by using this&nbsp;application?',
            answer: 'As SimFasset has NO ability to "wallet connect" or connect to any crypto wallet whatsoever there is <span class="txt-uppercase">no</span> risk to your coins.',
            isActive: false
          },
          {
            id: 4,
            question: 'Does any data within SimFasset come&nbsp;from the Flare&nbsp;API&nbsp;Portal?',
            answer: 'Currently all outside data is sourced from <a href="https://livecoinwatch.com" target="_blank">Live Coin Watch\'s</a> API via a lightweight data solution <a href="https://x.com/marklikeapenny" target="_blank">@marklikeapenny</a> created. I highly suggest you give Mark a follow on <a href="https://x.com/marklikeapenny" target="_blank">X</a>.',
            isActive: false
          },
          {
            id: 5,
            question: 'Are there any future plans to bring data from the Flare&nbsp;API&nbsp;Portal?',
            answer: 'Yes, there are future plans to bring data from the <a href="https://api-portal.flare.network/" target="_blank">Flare API Portal</a>.',
            isActive: false
          },
          {
            id: 6,
            question: 'Blockchain bridging: what is it and why is it&nbsp;needed?',
            answer: 'Blockchain bridges are protocols that facilitate the transfer of assets and data between different blockchain networks. They act as intermediaries, navigating the technical and security complexities of disparate networks to enable the flow of value&nbsp;frictionlessly.',
            isActive: false
          },
          {
            id: 7,
            question: 'What\'s the purpose of FAsset system on the Flare Network?',
            answer: 'In short, the <a href="https://docs.flare.network/tech/fassets/" target="_blank">FAsset</a> system allows non-smart contract blockchains like Bitcoin, XRPL, Dogecoin, etc. to be used trust-limitedly in a decentralized manner to inherit smart contracts capabilities on either the Flare or Songbird&nbsp;Network.',
            isActive: false
          },
          {
            id: 8,
            question: 'What is collateral and how many types&nbsp;of collateral exist on the Flare&nbsp;Network?',
            answer: 'Collateral acts as something pledged as security for repayment of a loan, to be forfeited in the event of a default. Two types of <a href="https://docs.flare.network/tech/fassets/collateral/" target="_blank">collateral</a> secure the <a href="https://docs.flare.network/tech/fassets/" target="_blank">FAsset</a> system; vault and pool&nbsp;collateral.',
            isActive: false
          },
          {
            id: 9,
            question: 'Why doesn\'t SimFasset\'s "Collateral Management" feature differentiate between various collateral&nbsp;types?',
            answer: 'To help simplify the concept of network <a href="https://docs.flare.network/tech/fassets/collateral/" target="_blank">collateral</a>, both the vault and pool collateral types are aggregated into one pool. This single pool reflects the overall required collateral needed in order to maintain the health and stability of the&nbsp;network.',
            isActive: false
          },
        ]
      }
    ],
    screensExcludesConfigs: [
      {
        id: 1,
        networkName: Constant.SONGBIRD_NETWORK,
        screens: [
          {
            id: 1,
            name: 'Supply Excludes',
            eventName: Constant.SETTINGS_EXCLUDES,
            isActive: false
          },
          {
            id: 2,
            name: 'Confgurations',
            eventName: Constant.SETTINGS_CONFIGURATIONS,
            isActive: true
          }
        ]
      },
      {
        id: 2,
        networkName: Constant.FLARE_NETWORK,
        screens: [
          {
            id: 1,
            name: 'Supply Excludes',
            eventName: Constant.SETTINGS_EXCLUDES,
            isActive: false
          },
          {
            id: 2,
            name: 'Confgurations',
            eventName: Constant.SETTINGS_CONFIGURATIONS,
            isActive: true
          }
        ]
      }
    ],
    screensCarousel: [
      {
        id: 1,
        name: 'Mint',
        isActive: false,
        url: './marquee-1.png'
      },
      {
        id: 2,
        name: 'Simulate',
        isActive: false,
        url: './marquee-2.png'
      },
      {
        id: 3,
        name: 'Speculate',
        isActive: false,
        url: './marquee-3.png'
      },
    ],
    screensCarouselHowTo: [
      {
        id: 1,
        name: 'Basic App Usage',
        isActive: false,
        url: 'https://www.youtube.com/embed/Uune9m8BwHI'
      },
      {
        id: 2,
        name: 'Collateral Management',
        isActive: false,
        url: 'https://www.youtube.com/embed/U6Cp_iUsoJI'
      },
      {
        id: 3,
        name: 'Settings Management',
        isActive: false,
        url: 'https://www.youtube.com/embed/9OqC-aIWdRI'
      },
    ],
    screensModal: {
      isActive: false,
      screens:[
        {
          id: 1,
          name: 'Collateral TVL',
          isActive: false,
        },
        {
          id: 2,
          name: 'Settings Help',
          isActive: false,
          subScreens: [
            {
              id: 2,
              isActive: false,
              title: 'Max Coin Price Setting',
            },
            {
              id: 3,
              isActive: false,
              title: 'Min Coin Price Setting',
            },
            {
              id: 4,
              isActive: false,
              title: 'Max Mint Supply Rate Setting',
            },
            {
              id: 5,
              isActive: false,
              title: 'Network Collateral Rate Setting',
            },
          ]
        },
        {
          id: 3,
          name: 'Minimum Collateral',
          isActive: false,
        },
        {
          id: 4,
          name: 'Share SMS',
          isActive: false,
        },
      ]
    },
    screensAccordion: [],
    isLoading: true,
    lastScreenId: 0,
    modalSubScreen: {},
    previousActiveAccordionId: 0,
    previousActiveSubNavScreenId: 5
  }),
  getters: {
    getScreensMintCollateral() {
      return this.screensMintCollateral
    },
    getScreensInformation() {
      return this.screensInformation
    },
    getScreensAccordion() {
      return this.screensAccordion
    },
    getScreensCarouselHowTo() {
      return this.screensCarouselHowTo
    },
    getScreensCarousel() {
      return this.screensCarousel
    },
    getScreensModal() {
      return this.screensModal
    },
    getScreenModalSubScreen() {
      return this.modalSubScreen
    },
    getScreensSubNav() {
      return this.screensSubNav
    },
    getShowModal() {
      return this.screensModal.isActive
    }
  },
  actions: {
    createAccordionScreens(coins) {
      if(this.screensAccordion.length === 0) {
        coins.forEach(c => this.screensAccordion.push({
          name: c.name,
          isActive: false,
          id: c.id
        }))
      }
    },
    deactivateScreensAccordions() {
      this.screensAccordion.forEach((a) => {
       a.isActive = false
      })
    },
    setActiveAccordionScreen(id) {
      if(this.previousActiveAccordionId != id) {
        this.deactivateScreensAccordions()
      }
      const aScreen = this.screensAccordion.find((a) => a.id === id)
      aScreen.isActive = !aScreen.isActive
      this.previousActiveAccordionId = id
    },


    setActiveInformationScreen(activeInformationScreen) {
      if(activeInformationScreen.questionIsActiveId === null) {
        this.resetActiveInformationScreen()
        this.getScreensInformation[activeInformationScreen.id - 1].isActive = true
      } else {
        this.resetActiveFaqAccordions()
        this.getScreensInformation[activeInformationScreen.id - 1].questions[activeInformationScreen.questionIsActiveId - 1].isActive = activeInformationScreen.questionIsActive
      }
    },
    resetActiveFaqAccordions() {
      this.getScreensInformation[1].questions.forEach((question) => {
        question.isActive = false;
      })
    },
    resetActiveInformationScreen() {
      this.getScreensInformation.forEach((screen) => {
        screen.isActive = false
      })
    },

    setDefaultActiveMintCollateralSceen() {
      this.resetActiveMintCollateralScreen()
      this.getScreensMintCollateral[0].isActive = true
    },
    setActiveMintCollateralScreen(activeScreen) {
      this.resetActiveMintCollateralScreen()
      this.getScreensMintCollateral[activeScreen.id - 1].isActive = true
    },
    resetActiveMintCollateralScreen() {
      this.getScreensMintCollateral.forEach(screen => {
        screen.isActive = false
      })
    },


    getScreensSettingsView(networkId) {
      return this.screensExcludesConfigs[networkId - 1].screens
    },
    setDefaultActiveSettingsScreen(networkId) {
      this.screensExcludesConfigs[networkId - 1].screens[0].isActive = false
      this.screensExcludesConfigs[networkId - 1].screens[1].isActive = true
    },
    setActiveSettingsScreen(screenSettings) {
      this.resetActiveSettingsScreen()
      this.screensExcludesConfigs[screenSettings.networkId - 1].screens[screenSettings.id - 1].isActive = screenSettings.isActive
    },
    resetActiveSettingsScreen() {
      this.screensExcludesConfigs.forEach(network => {
        network.screens.forEach( screen => {
          screen.isActive = false
        })
      })
    },


    getScreenSubNavId(screenName) {
      return this.screensSubNav.find((screen) => screen.name.toLowerCase() === screenName.toLowerCase()).id
    },
    getScreenSubNav(screenName) {
      const id = this.getScreenSubNavId(screenName)
      return this.screensSubNav[id - 1]
    },
    setActiveSubNavScreen(screenName) {
      const id = this.getScreenSubNavId(screenName)

      if(this.previousActiveSubNavScreenId != id) {
        this.deactivateScreensSubNav()
      }
      const snScreen = this.screensSubNav.find((a) => a.id === id)
      snScreen.isActive = !snScreen.isActive
      this.previousActiveSubNavScreenId = id
    },
    deactivateScreensSubNav() {
      this.screensSubNav.forEach((a) => {
       a.isActive = false
      })
    },


    setActiveScreenModal(settings) {
      this.screensModal.isActive = true
      const aScreenModal = this.screensModal.screens.find((a) => a.id === settings.id)
      aScreenModal.isActive = !aScreenModal.isActive

      if(settings.subScreenId) {
        this.modalSubScreen = aScreenModal.subScreens.find((a) => a.id === settings.subScreenId)
      }
    },
    deactivateScreensModal() {
      this.screensModal.isActive = false
      this.screensModal.screens.forEach((a) => {
        a.isActive = false
      })
    },
  }
})