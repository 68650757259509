export default Object.freeze({
  COIN_DATA_URL: 'https://pricing.simfasset.xyz/coins.json',
  SONGBIRD_NETWORK: 'Songbird Network',
  FLARE_NETWORK: 'Flare Network',
  //LOADING: 'Loading',
  //INTRODUCTORY: 'introductory',
  GET_STARTED: 'getStarted',
  MINTING: 'fmint',
  SIMULATE: 'simulate',
  SETTINGS: 'Settings',
  INFORMATION: 'Information',
  NETWORK: 'Network',
  SHARE: 'Share',
  SMS: 'Sms',
  TWITTER: 'Twitter',
  FACEBOOK: 'Facebook',
  RESET_MINTED_COINS: 'Reset',
  RESET_APP_DATA: 'ResetAppData',
  HELP: 'Help',

  LOCAL_STORAGE_COOKIE_ACCEPTANCE: 'cookiesAcceptance',
  LOCAL_STORAGE_KEY: 'simfasset',
  LOCAL_STORAGE_NETWORK_KEY: 'network',

  COOKIE_DISCLOSURE: 'Cookies-disclosure',
  COOKIE_ACCEPT: 'Cookies-accept',
  
  ACCORDION_ACTIVATE:'activateAccordion',
  ACCORDION_DEACTIVATE:'deactivateAccordions',

  INFORMATION_INFO: 'info',
  INFORMATION_FAQ: 'faq',

  SETTINGS_EXCLUDES: 'excludes',
  SETTINGS_CONFIGURATIONS: 'config',
  SETTING_RESET: 'reset',
  SETTINGS_NETWORK_MAX_PRICE_MULTIPLIER_ID: 1,
  SETTINGS_MAX_COIN_PRICE_MULTIPLIER_ID: 2,
  SETTINGS_MIN_COIN_PRICE_MULTIPLIER_ID: 3,
  SETTINGS_MINT_MAX_SUPPLY_RATE_ID: 4,
  SETTINGS_MINT_COLLATERAL_RATE_ID: 5,

  MINT_FASSETS: 'mint',
  COLLATERAL_BACKING: 'collateral',
  COLLATERAL_TVL: 'tvl',
  COLLATERAL_MANAGEMENT_TYPE_DUAL: 'dual',
  COLLATERAL_MANAGEMENT_TYPE_TRI: 'tri',

  ONE: 'one',
  ONE_TENTH: 'one-tenth',
  ONE_HUNDRETH: 'one-hundreth',
  ONE_NUM: 0.01,
  ONE_TENTH_NUM: 0.001,
  ONE_HUNDRETH_NUM: 0.0001,
  MAX: 'max',
  RESET: 'reset',

  BTC: "BTC",
  LTC: "LTC",
  DOGE: 'DOGE',
  ALGO: 'ALGO',
  XRP: 'XRP',

  FETCH_PRICE_DURATION: 90000,

  BLACK: '000000',
  WHITE: 'FFFFFF',
  WHITE_RGB: '255,255,255',
  PURPLE: '5300bb',
  PURPLE_XLIGHT: 'AB2EFF',
  PURPLE_XXLIGHT: 'CBA3FF',
  PURPLE_XLIGHT_RGB: '183, 75, 255',
  PURPLE_XXLIGHT_RGB: '203, 163, 255',
  PURPLE_CHART_MAX_RGB: '115, 3, 255',
  PURPLE_RGB: '83, 0, 187',
  PURPLE_LIGHT_RGB: '117, 52, 200',
  RED: 'E21D4E',
  RED_LIGHT: 'E84d73',
  RED_RGB: '226, 29, 78',
  RED_LIGHT_RGB: '232, 77, 115',
  RED_CHART_MAX_RGB: '255, 45, 97',
  GREY: '4a4a4a',
  GREY_MEDIUM: 'D9D8D9',
  GREY_MEDIUM_RGB: '217, 216, 217',
  GREY_LIGHT_RGB: '244, 244, 244',
  GREY_LIGHT_STROKE: 'CBCBCB',
  GREY_LIGHT_STROKE_RGB: '203, 203, 203',
  GREY_XXLIGHT: 'F4F4F4',
  BLUE: '627EEA',
  BLUE_RGB: '98, 126, 234',

  CHART_TYPE_STATIC: 'Static',
  CHART_TYPE_DYNAMIC: 'Dynamic',
  MOBILE_MAX_WIDTH: '1024'
})