<template>
  <div 
    class="slider-container"
    :class="{'p-modified': modifiedSetting}"
  >
    <div class="flex">
      <h3> {{ setting.name }} </h3>
      <div>
        <div
          v-if="setting.type === 'multiplier'"
          class="flex"
        >
          <div
            v-if="!modifiedSetting"
            class="title-xxsmall badge"
          >
            {{ valueTick }}<span class="title-xxsmall">x</span>
          </div>
          <Button
            v-else
            class="title-xxsmall modified"
            :eventName="constant.SETTING_RESET"
            @clicked="clicked($event)"
          >
            {{ valueTick }}<span class="title-xxsmall">x</span>
          </Button>
        </div>
        <div
          v-else
          class="flex"
        >
          <div
            v-if="!modifiedSetting"
            class="title-xxsmall badge"
          >
            {{ valueTickPercentage }}<span class="title-xxsmall">%</span>
          </div>
          <Button
            v-else
            class="title-xxsmall modified"
            :eventName="constant.SETTING_RESET"
            @clicked="clicked($event)"
          >
            {{ valueTickPercentage }}<span class="title-xxsmall">%</span>
          </Button>
        </div>
      </div>
      <Button
        class="help"
        :eventName="constant.HELP"
        @clicked="showHelpModal($event)"
      >
        <Question 
          class="question"
        />
      </Button>
    </div>

    <InputRange
      :constant="constant"
      :disabled="false"
      :classes="classes"
      :styles="interactiveRangeStyles"
      :modelValue="modelValue"
      @sliderValue="sliderValueUpdate($event)"
    />

    <div 
      v-if="setting.type === 'multiplier'" 
      class="range flex"
    >
      <span v-if="showWholeNumber">{{ setting.min }}x</span>
      <span v-else>{{ currencyFormatter(setting.min) }}x</span>
      <span v-if="showWholeNumber">{{ setting.max }}x</span>
      <span v-else>{{currencyFormatter(setting.max) }}x</span>
    </div>
    <div 
      v-else 
      class="range flex"
    >
      <span>{{ currencyFormatter(setting.min * 100) }}%</span>
      <span>{{ currencyFormatter(setting.max * 100) }}%</span>
    </div>
  </div>
</template>
<script setup>
  import { ref, reactive, computed } from 'vue'
  import InputRange from './InputRange.vue'
  import Question from '../_icons/Question.vue'
  import Button from './Button.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    setting: {
      type: Object,
      default: () => {}
    },
    networkName: {
      type: String,
      default: ''
    }
  })

  const emit = defineEmits([
    'modalScreen',
    'updateConfiguration',
  ])

  const constant = props.constant
  const modelValue = ref(50)
  const dataTable = []

  const classes = computed(() => {
    return {
      'setting-slider' : true
    }
  })

  const interactiveRangeStyles = computed(() => {
    return {'background' : `linear-gradient(90deg, rgba(${ constant.PURPLE_RGB }, 1) ${modelValue.value}%, rgba(${ constant.PURPLE_XXLIGHT_RGB }, 1) ${modelValue.value}%)`}
  })

  const modifiedSetting = computed(() => {
    return props.setting.default != props.setting.set
  })

  const valueTick = computed(() => {
    setModelValue()
    let tickVal = Math.round(dataTable[modelValue.value].val * 10) / 10

    if(props.setting.valueType === 'hundredth' || props.setting.valueType === 'tenth') {
      tickVal = (Math.round(dataTable[modelValue.value].val * 100) / 100).toFixed(2)
    }
    return tickVal
  })

  const valueTickPercentage = computed(() => {
    setModelValue()
    return (dataTable[modelValue.value].val * 100).toFixed(2)
  })

  const showWholeNumber = computed(() => {
    return props.setting.min >= 10 ? true : false
  })

  const setModelValue = () => {
    modelValue.value = getRangeTick(props.setting.set).id - 1
  }

  const sliderValueUpdate = (event) => {
    modelValue.value = parseInt(event)
    emit('updateConfiguration', settings(dataTable[modelValue.value].val))
  }

  const clicked = (event) => {
    emit('updateConfiguration', settings(props.setting.default))

    //Reset Modified Price Bug - Revist
    //emit('updateConfiguration', settings(''))

    const tick = getRangeTick(props.setting.default)
    modelValue.value = tick.id - 1
  }

  const showHelpModal = (event) => {
    const modalSettings = {
      id: 2,
      subScreenId: props.setting.id
    }
    emit('modalScreen', modalSettings)
  }

  const settings = (setValue) => {
    return {
      id: props.setting.id,
      networkName: props.networkName,
      set: setValue === '' ? null : setValue,
      type: constant.SETTINGS_CONFIGURATIONS
    }
  }

  const createDataRangeTable = () => {
    for(let i = 0; i < 101; i++) {
      dataTable.push({
        id: i + 1,
        val: calculateValueBaseOnType(i, props.setting.valueType),
      })
    }

    setDefaultValue()
  }

  const setDefaultValue = () => {
    const len = dataTable.length
    let id = 0

    //Find the ID to the closest Default Value
    for(let i = 0; i < len; i++) {
      if(dataTable[i].val <= props.setting.default) {
        id = dataTable[i].id
      }
    }
    dataTable[id - 1].val = props.setting.default
  }

  const calculateValueBaseOnType = (i, value) => {
    const rangeTickVal = (props.setting.max - props.setting.min) / 101

    let val = 0
    switch(value) {
      case 'default' : val = Math.ceil((i * rangeTickVal) + props.setting.min)
      break
      case 'tenth' : val = Math.floor(((i * (props.setting.max - props.setting.min) / 99) + props.setting.min) * 100) / 100
      break
      case 'hundredth' : val =  Math.round(((i * ((props.setting.max - props.setting.min) / 100)) + props.setting.min) * 100) / 100
      break
      case 'thousandth' : val = Math.round(((i * ((props.setting.max - props.setting.min) / 100)) + props.setting.min) * 1000) / 1000
      break
      default : val = 1
    }
    return val
  }

  const getRangeTick = (value) => {
    const rangeData = ref(0)
    const len = dataTable.length

    for(let i = 0; i < len; i++) {
      let t = dataTable[i]

      if(t.val <= value) {   
        rangeData.value = t
      }
    }
    return rangeData.value
  }

  const currencyFormatter = (value, decimal = 2) => {
    return value.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  }

  //Intialize Price Simulator - Set ModelValue
  const initializeSliderInputRange = () => {
    createDataRangeTable()
    setModelValue()
  }

  initializeSliderInputRange()
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  h3 {
    text-transform: initial;
    text-align: right;
    font-size: $FONT-SMALL;
    color: $GREY;
    font-weight: 400;
  }

  .slider-container {
    position: relative;
    margin: 0px auto 0 auto;
    overflow-y: hidden;
    min-width: 340px;
    color: $BLACK;
    padding: 20px 5px;
    background-color: transparent;
    border-top: thin solid $WHITE;
    border-bottom: thin solid $GREY-XXXLIGHT;

    &:first-child {
      border-top: none;
    }

    &.p-modified {
     background-color: $WHITE-OFF;
    }

    .title-xxsmall {
      font-weight: 600;
      letter-spacing: $LETTER-SPACING;
    }

    .flex {
      justify-content: center;

      .badge, .modified {
        background-color: $BLACK-XLIGHT;
        border: thin solid $GREY-XXLIGHT;
        color: $GREY-LIGHT;
        margin-left: 10px;
        border-radius: 3px;
        padding: 4px 0;
        width: 70px;
      }
      .modified {
        background-color: $PURPLE-XXXXLIGHT;
        border: thin solid $PURPLE-XXLIGHT;
        color: $PURPLE;
      }
      .question {
        margin: 7px 7px 0 7px;
      }
    }

    .range {
      font-size: $FONT-XXXSMALL;
      padding: 35px 30px 0 30px;

      &.flex {
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;

        @include breakpoint(sm) {
          width: 94%;
        }
        @include breakpoint(md) {
          width: 90%;
        }
        @include breakpoint(lg) {
          width: 87%;
        }
        @include breakpoint(xl) {
          width: 87%;
        }
        @include breakpoint(xxl) {
          width: 87%;
        }
        @include breakpoint(xxxl) {
          width: 87%;
        }
      }
    }
  }
</style>