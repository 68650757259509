<template>
  <div class="info mt-50">
    <!-- <div class="carousel-container">
      <h2>How To: {{ screensCarousel[slideIndex].name }}</h2>
      <Carousel
        class="no-border-bottom"
        :items-to-show="1"
        :transition="300"
        :autoplay="0"
        :mouseDrag="true"
        :touchDrag="true"
        :wrapAround="true"
        @slide-start="slideChange($event)"
      >
        <Slide v-for="slide in screensCarousel" :key="slide.id">
          <div
            class="carousel__item"
          >
            <iframe 
              :src="slide.url" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen
            >
            </iframe>
          </div>
        </Slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </Carousel>
    </div> -->

    <section class="no-border-top bg-white">
      <h2 class="mt-30 mb-30">Why SimFasset?</h2>
      <div class="columns-dual">
        <div class="column">
          <p>This web app empowers anyone with a modern web browser to dynamically predict the future Minimum {{ activeNetwork.ticker }} Price, based on the number of {{ activeNetwork.ticker }} coins needed to support the <a href="https://docs.flare.network/tech/fassets/" target="_blank">FAsset's</a> collateral system. Please understand the number of prediction possibilities are almost infinite when arriving at a Minimum {{ activeNetwork.ticker }} Price due to various hypothetical scenarios and dynamic data&nbsp;inputs.</p>
          <p class="txt-bold">How will you stand by your findings?</p>
        </div>
        <div class="column">
          <p>Be sure to share your discoveries with others and help them learn about the unique offerings the {{ activeNetwork.name }} <a href="https://docs.flare.network/tech/fassets/" target="_blank">FAsset</a> system provides. Remember we are still very early so have fun and try to dream&nbsp;a&nbsp;bit!</p>
          <p>Please Note: This app will not actually facilitate the <a href="https://docs.flare.network/tech/fassets/" target="_blank">FAsset</a> minting process. The purpose is to help educate us on how the <a href="https://docs.flare.network/tech/fassets/" target="_blank">FAsset</a> system may impact the native coin's price via&nbsp;simulation.</p>
        </div>
      </div>
      </section>
      <section class="no-border-top">
      <h2 class="mt-30 mb-30">What Is The FAsset System?</h2>
      <div class="columns-dual">
        <div class="column">
          <p>At its core the <a href="https://docs.flare.network/tech/fassets/" target="_blank">FAsset</a> system is one of the primary features for both the Flare and Songbird Network. This feature allows coins like BTC, XRP, DOGE, etc. without smart contract abilities to inherit these capabilities via minting those assets onto either network in a trust-limited, insured, and decentralized way for a small&nbsp;fee.</p>
          <p>Why is this important? Smart contracts enable digital assets to harness the characteristics of programmability which creates an endless array of possibilities in the digital realm. Another way to think about it, imagine holding BTC or XRP in a self-custody wallet where it does very little for you by just sitting there. Now try to imagine giving those same assets the ability to also earn yield risk-free while maintaining full control over those assets. Pretty cool if you&nbsp;ask&nbsp;me!</p>
          <p>In order for <a href="https://docs.flare.network/tech/fassets/" target="_blank">FAsset</a> system to work properly any value &#40;BTC / XRP / DOGE&#41; minted onto either the Flare or Songbird Network must be backed by a necessary amount of&nbsp;collateral. This collateral is provided by an <a href="https://docs.flare.network/tech/fassets/#agents" target="_blank">Agent</a> of the network for a small fee and is set aside for use as a remedy in any unforeseen network risks should any issues arise. Another way to think about this collateral is an insurance acting as a fail safe mechanism protecting any and all value minted onto the&nbsp;network.</p>
        </div>
        <div class="column">
          <p>Currently three buckets of asset types have been deemed as the anchor for each network's collateral in the <a href="https://docs.flare.network/tech/fassets/" target="_blank">FAsset</a> system; {{ activeNetwork.ticker }}, Stable Coins, and BTC/ETH for each corresponding Network.</p>
          <p>Remember that small fee mentioned above for minting assets onto either network? This is the earning incentive for third party <a href="https://docs.flare.network/tech/fassets/#agents" target="_blank">Agents</a> in providing the necessary required collateral needed to support any and all value minted onto the network. Keep in mind, the more value that's minted will likely result in either additional native network coins being locked up in the collateral system by participating <a href="https://docs.flare.network/tech/fassets/#agents" target="_blank">Agents</a>, or a higher native network's&nbsp;coin&nbsp;price.</p>
          <p>It's also worth noting that all assets minted onto the network can easily be redeemed, at any time, back to your self-custody wallet for a small&nbsp;fee.</p>
          <p>SimFasset's goal is to empower the community by providing a tool to help us better understand how any value minted onto the Flare or Songbird Network may directly impact the native coin's price, one way&nbsp;or&nbsp;another.</p>
        </div>
      </div>
    </section>
    <section class="disclaimer no-border-top">
      <h2 class="mt-30 mb-30">Disclaimer</h2>
      <div class="columns-dual">
        <div class="column">
            <p>In no way, shape, or form is SimFasset a supplement for any sort of investment research! The intent of this tool is to provide you with a fun and compelling way to manipulate and visualize data based on your personal&nbsp;opinions.</p>
        </div>
        <div class="column">
          <p> It's up to you as an investor to do your own extensive research prior to making any financial investment&nbsp;decisions.</p>
        </div>
      </div>
    </section>

    <section class="donations no-border-bottom pb-30">
      <h2 class="mt-30 mb-30">Tips / Donations</h2>
      <div class="columns-dual">
        <div class="column">
          <p>If you find value using SimFasset then please consider supporting my work with a donation.</p>
          <p>Please note no grants, fees, or earnings have been received for the numerous months poured into creating&nbsp;this&nbsp;app.</p>
        </div>
        <div class="column">
          <p>This tool was created by a community member for the&nbsp;community! Any FLR and/or SGB donated are greatly&nbsp;appreciated and will help continued developement work/updates.</p>
        </div>
      </div>
      
      <div class="flex mt-20 mb-30">
        <QrCode />
      </div>
      <div class="wallet-address-flr-sgb mb-20">0x339EcDA5E9c9F307CBe14F38d0F2b9a5BB78d833</div>
    </section>

  </div>
</template>
<script setup>
  import { ref } from 'vue'
  import FlareNetworkIcon from '../../../_icons/Network/FlareNetworkIcons.vue'
  import SongbirdNetworkIcon from '../../../_icons/Network/SongbirdNetworkIcons.vue'
  import QrCode from '../../../_icons/QrCode.vue'
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
  import 'vue3-carousel/dist/carousel.css'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    screensCarousel: {
      type: Array,
      default: () => []
    }
  })

  const constant = props.constant
  const slideIndex = ref(0)

  const slideChange = (event) => {
    slideIndex.value = event.slidingToIndex > props.screensCarousel.length - 1 || event.slidingToIndex === -1 ? 0 : event.slidingToIndex
  }
</script>
<style lang="scss">
  @import '../../../../assets/scss/_variables.scss';
  @import '../../../../assets/scss/mixins/layout.scss';

.info {

  h2 {
    text-transform: unset;
    font-weight: 600;
    font-size: $FONT-LARGE;
  }

  h2, p, li {
    color: $BLACK;
  }

  section {
    padding-bottom: 10px;
    border-top: thin solid $WHITE;
    border-bottom: thin solid $GREY-XXXLIGHT;

    &.no-border-bottom {
      border-bottom: none;
    }
  }

  .bg-white {
    background-color: $WHITE;
  }

  .column {
    p {
      margin: 0 10px;

      &.mt-10 {
        margin-top: 10px;
      }

      &.mt-20 {
        margin-top: 20px;
      }
    }
    p, ul {
      margin-bottom: 20px;

      &.mb-20 {
        margin-bottom: 20px;
      }
    }

    li::before {
      content: "- ";
      color: $BLACK;
    }
  }

  .carousel-container {
    background-color: $WHITE;
    border-top: thin solid $WHITE;
    border-bottom: thin solid $GREY-XXXLIGHT;
    padding-bottom: 40px;

    .carousel {
      margin: 0 auto;

      &.no-border-bottom {
        border-bottom: none;
      }

      .carousel__item {
        background-color: $BLACK;
        font-weight: 500;
        width: 100%;
        min-height: 225px;
        font-size: 20px;

        iframe {
          aspect-ratio: 16 / 9;
          width: 100% !important;
        }
      }

      .carousel__prev, .carousel__next {
        box-sizing: content-box;
        color: $BLACK;
        background-color: $WHITE-MEDIUM;

          .carousel__icon {
            width: 50px;
            height: 50px;
          }
      }

      .carousel__pagination-button::after {
        background-color: $BLACK-LIGHTX;
      }
      .carousel__pagination-button--active::after {
        background-color: $BLACK;
      }
    }
  }

  .donations {
    background-color: $WHITE;

    .flex {
      flex-direction: row;
      width: 100%;
      justify-content: center;
    }

    .wallet-address-flr-sgb {
      color: $PURPLE;
      font-size: $FONT-XSMALL;
      letter-spacing: $LETTER-SPACING;
    }
  }
  .disclaimer {
    background-color: initial;
  }
}
</style>