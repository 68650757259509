<template>
  <Transition>
    <div 
      class="accordion-card" 
      v-if="isActiveAccordion"
    >
      <MintAssetControlsCoinDetails
        :constant="constant" 
        :coin="coin"
        :configs="configs"
        :activeNetwork="activeNetwork"
        :collateralFAssetIsActive="collateralFAssetIsActive"
        :networkCollateralAllocation="networkCollateralAllocation"
        :collateral="currencyTruncator($collateral)"
        :circulatingSupply="currencyTruncator(circulatingSupply(coin))"
        :isNativeCollateral="isNativeCollateral"
        :USDcollateral="currencyTruncator(USDcollateral)"
        :NATIVEcollateral="currencyTruncator(NATIVEcollateral)"
        :FASSETcollateral="currencyTruncator(FASSETcollateral)"
        @updateModifiedCoinPrice="updateModifiedCoinPrice($event)"
      />
      <MintAssetControlsMintDetails 
        :constant="constant" 
        :coin="coin"
        :configs="configs"
        :activeNetwork="activeNetwork"
        @updateMintQuantity="updateMintQuantity($event)"
      />
    </div>
  </Transition>
</template>
<script setup>
  import { ref, computed } from 'vue'
  import MintAssetControlsCoinDetails from './MintAssetControlsCoinDetails.vue'
  import MintAssetControlsMintDetails from './MintAssetControlsMintDetails.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    coin: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    isActiveAccordion: {
      type: Boolean,
      default: false
    },
    configs: {
      type: Object,
      default: () => {}
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const emit = defineEmits([
    'updateMintQuantity',
    'updateModifiedCoinPrice',
  ])
  const constant = props.constant

  const mPrice = computed(() => {
    return props.coin.modifiedPrice > 0 ? props.coin.modifiedPrice : props.coin.price
  })

  const $collateral = computed(() => {
    return (parseFloat(props.coin.mintQuantity) * parseFloat(mPrice.value * getConfigsSettingById(constant.SETTINGS_MINT_COLLATERAL_RATE_ID).set))
  })

  const circulatingSupply = (coin) => {
    return coin.circulatingSupply
  }

  const isNativeCollateral = computed(() => {
    return NATIVEcollateral.value > 0
  })

  const USDcollateral = computed(() => {
    return $collateral.value * props.networkCollateralAllocation[0].set
  })

  const NATIVEcollateral = computed(() => {
    if(props.collateralFAssetIsActive) {
      return $collateral.value * props.networkCollateralAllocation[2].set
    }
    return $collateral.value * props.networkCollateralAllocation[1].set
  })

  const FASSETcollateral = computed(() => {
    if(props.collateralFAssetIsActive) {
      return $collateral.value * props.networkCollateralAllocation[1].set
    }
    return 0
  })

  const currencyFormatter = (value, decimalCount = 2) => {
    return value.toFixed(decimalCount).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  }

  const currencyTruncator = (value) => {
    let newValue = parseInt(value)
    let len = newValue.toString().length
    
    if(len >= 7 && len < 10)
      return newValue = `${currencyFormatter(newValue/1000000, 3)}M`
    else if(len >= 10 && len <= 12) 
      return newValue=`${currencyFormatter(newValue/1000000000, 3)}B`
    else if(len > 12) 
      return newValue=`${currencyFormatter(newValue/1000000000000, 3)}T`
    
    return newValue = currencyFormatter(newValue)
  }

  const updateMintQuantity = (event) => {
    emit('updateMintQuantity', event)
  }

  const updateModifiedCoinPrice = (event) => {
    emit('updateModifiedCoinPrice', event)
  }

  const getConfigsSettingById = (settingId) => {
    const setting = props.configs.settings.find((setting) => setting.id === settingId)
    return setting
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';

  .accordion-card {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 10px 10px 0 10px;
    height: auto;
    overflow-y: hidden;
  }

  .v-enter-from {
    height: 0;
  }
  .v-enter-to {
    height: $ACCORDION-HEIGHT;
  }
  .v-enter-active {
    transition: height 0.2s ease-out;
  }

  .v-leave-from {
    height: $ACCORDION-HEIGHT;
  }
  .v-leave-to {
    height: 0;
  }
  .v-leave-active{
    transition: height 0.3s ease-in;
  }
</style>