<template>
  <div class="mint-container flex">
    <h2>Mint Amount</h2>
    <MintAssetControlsMintDetailsForm
      :constant="constant"
      :coin="coin"
      :configs="configs"
      :activeNetwork="activeNetwork"
      :maxMintRate="maxMintRate"
      @updateMintQuantity="updateMintQuantity($event)"
    />
  </div>
</template>
<script setup>
  import { ref, computed, watch } from 'vue'
  import MintAssetControlsMintDetailsForm from './MintAssetControlsMintDetailsForm.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    coin: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    }
  })

   const emit = defineEmits([
    'updateMintQuantity',
  ])

  const constant = props.constant
  const mintRateType = ref('')
  const maxMintRate = ref('')

  const calCollateralRate = () => {
    return getConfigsSettingById(constant.SETTINGS_MINT_COLLATERAL_RATE_ID).set
  }

  const requiredCollateralRate = () => {
    return (calCollateralRate() * 100).toFixed(0)
  }

  const currencyFormatter = (value, decimal = 2) => {
    return value.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  }

  const updateMintQuantity = (event) => {
    emit('updateMintQuantity', event)
  }

  const getConfigsSettingById = (settingId) => {
    const setting = props.configs.settings.find((setting) => setting.id === settingId)
    return setting
  }

  watch(() => [
    getConfigsSettingById(constant.SETTINGS_MINT_COLLATERAL_RATE_ID).set,
    getConfigsSettingById(constant.SETTINGS_MINT_MAX_SUPPLY_RATE_ID).set
    ], 
    (newValue, oldValue) => 
  {
    //calCollateralRate()
    maxMintRate.value = newValue[1]
  })

  //calCollateralRate()
  maxMintRate.value = getConfigsSettingById(constant.SETTINGS_MINT_MAX_SUPPLY_RATE_ID).set
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';

  .mint-container {
    position: relative;
    flex-direction: column;
    background-color: $BLACK-LIGHTX;
    margin: 30px 0 20px 0;

    h2 {
      margin-bottom: 20px;
      font-size: $FONT-SMALL;
    }
  }
</style>