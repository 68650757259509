<template>
  <div class="container-txt flex">
    <h3 
      class="flex"
      :class="{ 
        'color-red': activeNetwork.name === constant.SONGBIRD_NETWORK,
        'color-grey': collateralRequired === 0
      }"
    >
      <ActiveNetworkLogo 
        :constant="constant"
        :activeNetwork="activeNetwork"
        :showSolidDefaultLogo="false"
        :showCircleLogo="true"
        :flrWidth="31"
        :flrHeight="30"
        :flrSize="0.9"
        :flrColor="collateralRequired === 0 ? constant.GREY : constant.PURPLE"
        :sgbWidth="31"
        :sgbHeight="30"
        :sgbSize="0.9"
        :sgbColor="collateralRequired === 0 ? constant.GREY :constant.RED"
      />
      <span class="mobile">{{ currencyTruncator(numCoins, 3) }}</span>
      <span class="desktop">{{ coinCountFormatter(numCoins) }}</span>
    </h3>

    <h4
      :class="{ 
        'color-red': activeNetwork.name === constant.SONGBIRD_NETWORK,
        'color-grey': collateralRequired === 0
      }"
    >
      Total {{ activeNetwork.ticker }} Needed For Minted FAssets
    </h4>
    
    <div class="tvl flex">
      <h4
        class="collateral mobile"
        :class="{ 
          'color-red': activeNetwork.name === constant.SONGBIRD_NETWORK,
          'color-grey': collateralRequired === 0
        }"
      >
       {{ activeNetwork.ticker }} TVL - ${{ currencyTruncator(collateralRequired) }}
      </h4>
      <h4
        class="collateral desktop"
        :class="{ 
          'color-red': activeNetwork.name === constant.SONGBIRD_NETWORK,
          'color-grey': collateralRequired === 0
        }"
      >
      {{ activeNetwork.ticker }} TVL - ${{ currencyFormatter(collateralRequired) }}
      </h4>
      <Button
        class="tvl-btn"
        :class="{'bg-color-red-xlight' : activeNetwork.name === constant.SONGBIRD_NETWORK}"
        :eventName="constant.COLLATERAL_TVL" 
        @clicked="clicked($event)"
      >
        {{ collateralPercentage }}%
      </Button>
    </div>

  </div>
</template>
<script setup>
  import { ref, computed } from 'vue'
  import ActiveNetworkLogo from '../_icons/Network/ActiveNetworkLogo.vue'
  import Button from '../_generics/Button.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    configs: {
      type: Object,
      default: () => {}
    },
    totalCollateralRequired: {
      type: Number,
      default: 0
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const emit = defineEmits([
    'modalScreen',
  ])

  const collateralPercentage = computed(() => {
    if(props.collateralFAssetIsActive) {
      return parseInt(props.networkCollateralAllocation[2].set * 100)
    }
    return parseInt(props.networkCollateralAllocation[1].set * 100)
  })

  const collateralRequired = computed(() => {
    if(props.collateralFAssetIsActive) {
      return props.totalCollateralRequired * props.networkCollateralAllocation[2].set
    }
    return props.totalCollateralRequired * props.networkCollateralAllocation[1].set
  })

  const tradableSupply = computed(() => {
    return props.activeNetwork.circulatingSupply - props.configs.topHoldersTotalCoinExclusion
  })

  const numCoins = computed(() => {
    let price = props.activeNetwork.modifiedPrice != 0 ? props.activeNetwork.modifiedPrice : props.activeNetwork.price
    let collateralRate = props.collateralFAssetIsActive ? props.networkCollateralAllocation[2].set : props.networkCollateralAllocation[1].set
    let coins = (props.totalCollateralRequired * collateralRate) / price

    if(coins >= tradableSupply.value) {
      coins = tradableSupply.value
    }
    return coins
  })

  const currencyFormatter = (value, decimalCount = 2) => {
    if( value.toString().length > 9)
      return numberFormatter(value.toFixed(decimalCount))

    return numberFormatter(value.toFixed(decimalCount))
  }

  const coinCountFormatter = (value) => {
    return numberFormatter(value.toFixed(0))
  }

  const numberFormatter = (value) => {
    return value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  }

  const currencyTruncator = (value, decimalCount = 3) => {
    let newValue = parseInt(value)
    let len = newValue.toString().length

    if(len >= 7 && len < 10)
      return newValue = `${currencyFormatter(newValue/1000000, decimalCount)}M`
    else if(len >= 10 && len <= 12) 
      return newValue=`${currencyFormatter(newValue/1000000000, decimalCount)}B`
    else if(len > 12) 
      return newValue=`${currencyFormatter(newValue/1000000000000, decimalCount)}T`
    
    return newValue = currencyFormatter(newValue)
  }

  const clicked = (event) => {
    const settings = {
      id: 1
    }
    emit('modalScreen', settings)
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';
  
  .container-txt {
    min-width: 350px;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(lg) {
      margin-left: 30px;
    }

    @include breakpoint(xl) {
      margin-left: 30px;
    }

    @include breakpoint(xxl) {
      margin-left: 30px;
    }

    @include breakpoint(xxxl) {
      margin-left: 30px;
    }

    h3, h4, p {
      color: $PURPLE;
    }
    h3 {
      line-height: $FONT-DEFAULT-SIZE;
      svg {
        margin-right: 10px;
        min-width: 28px;
      }
    }
    h4 {
      padding-top: 3px;
      line-height: $FONT-LARGE;
      font-size: $FONT-XXSMALL;
      font-weight: 400;

      &:nth-child(1) {
        font-size: $FONT-XXSMALL;
        
        @include breakpoint(md) {
          font-size: $FONT-DEFAULT-SIZE;
          font-weight: 500;
        }
        @include breakpoint(lg) {
          font-size: $FONT-DEFAULT-SIZE;
          font-weight: 500;
        }
        @include breakpoint(xl) {
          font-size: $FONT-DEFAULT-SIZE;
          font-weight: 500;
        }
        @include breakpoint(xxl) {
          font-size: $FONT-DEFAULT-SIZE;
          font-weight: 500;
        }
        @include breakpoint(xxxl) {
          font-size: $FONT-DEFAULT-SIZE;
          font-weight: 500;
        }
      }
      &.collateral  {
        font-size: $FONT-XSMALL;
        line-height: $FONT-DEFAULT-SIZE;
        font-weight: 600;

        &.desktop {
          line-height: $FONT-LARGE;
        }
      }
    }

    .tvl {
      &.flex {
        .tvl-btn {
          background-color: $PURPLE-XXXLIGHT;
          color: $WHITE;
          border-radius: 5px;
          margin-left: 10px;
          padding: 2px 6px;
          font-size: $FONT-SMALL;
          font-weight: 600;
        }
      }
    }

  }
</style>