<template>
  <Transition name="modal">
    <div
      v-if="show"
      class="modal"
    > 

      <div 
        class="modal-bg"
        @click.prevent="close($event)"
      >
      </div>

      <div class="modal-container">
        <div class="modal-content">
          <header>
            <Button 
              class="control-btn default" 
              :eventName="constant.GET_STARTED" 
              @click.prevent="close($event)"
            >
              <Close 
                :color="constant.BLACK"
                :width="22"
                :height="20"
                :size="0.8"
              />
            </Button>
          </header>
          <ModalShareSms
            v-if="screens[3].isActive"
            :start="screens[3].isActive"
            :pathName="pathName"
            @hideModal="close($event)"
          />
          <ModalMinCollateral
            v-if="screens[2].isActive"
            :constant="constant"
            :activeNetwork="activeNetwork"
            @nextScreen="nextScreen($event)"
          />
          <ModalHelp
            v-if="screens[1].isActive"
            :constant="constant"
            :activeNetwork="activeNetwork"
            :subScreen="subScreen"
          />
          <ModalCollateralTVL
            v-if="screens[0].isActive"
            :constant="constant"
            :activeNetwork="activeNetwork"
            :isNativeCollateral="isNativeCollateral"
            :FASSETcollateral="currencyTruncator(FASSETcollateral)"
            :NATIVEcollateral="currencyTruncator(NATIVEcollateral)"
            :USDcollateral="currencyTruncator(USDcollateral)"
            :totalCollateralRequired="currencyTruncator($collateral)"
            :collateralFAssetIsActive="collateralFAssetIsActive"
            :networkCollateralAllocation="networkCollateralAllocation"
            @collateralScreen="collateralScreen($event)"
          /> 
        </div>
      </div>

    </div>
  </Transition>
</template>
<script setup>
  import { computed } from 'vue'
  import Button from '../_generics/Button.vue'
  import Close from '../_icons/Close.vue'
  import ModalHelp from './ModalHelp.vue'
  import ModalShareSms from './ModalShareSms.vue'
  import ModalMinCollateral from './ModalMinCollateral.vue'
  import ModalCollateralTVL from './ModalCollateralTVL.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    totalCollateralRequired: {
      type: Number,
      default: 0
    },
    subScreen: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    },
    screens: {
      type: Array,
      default: () => []
    },
    pathName: {
      type: String,
      default: ''
    }
  })

  const emit = defineEmits([
    'hideModal',
    'nextScreen',
    'collateralScreen'
  ])

  const close = (event) => {
    emit('hideModal', null) 
  }

  const nextScreen = (event) => {
    close(null)
    emit('nextScreen', event)

  }

  const collateralScreen = (event) => {
    close(null)
    emit('collateralScreen', event)
  }

  const currencyFormatter = (value, decimalCount = 2) => {
    return value.toFixed(decimalCount).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  }

  const currencyTruncator = (value) => {
    let newValue = parseInt(value)
    let len = newValue.toString().length
    
    if(len >= 7 && len < 10)
      return newValue = `${currencyFormatter(newValue/1000000, 3)}M`
    else if(len >= 10 && len <= 12) 
      return newValue=`${currencyFormatter(newValue/1000000000, 3)}B`
    else if(len > 12) 
      return newValue=`${currencyFormatter(newValue/1000000000000, 3)}T`
    
    return newValue = currencyFormatter(newValue)
  }

  const $collateral = computed(() => {
    return props.totalCollateralRequired
  })

  const isNativeCollateral = computed(() => {
    return NATIVEcollateral.value > 0
  })

  const collateralFAssetIsActive = computed(() => {
    return props.activeNetwork.collateralManagement[1].isActive
  })

  const networkCollateralAllocation = computed(() => {
    if(collateralFAssetIsActive.value) {
      return props.activeNetwork.collateralManagement[1].allocation
    }
    return props.activeNetwork.collateralManagement[0].allocation
  })

  const NATIVEcollateral = computed(() => {
    if(collateralFAssetIsActive.value) {
      return $collateral.value * networkCollateralAllocation.value[2].set
    }
    return $collateral.value * networkCollateralAllocation.value[1].set
  })

  const FASSETcollateral = computed(() => {
    if(collateralFAssetIsActive.value) {
      return $collateral.value * networkCollateralAllocation.value[1].set
    }
    return 0
  })

  const USDcollateral = computed(() => {
    return $collateral.value * networkCollateralAllocation.value[0].set
  })
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  .modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    overflow-y: hidden;
    height: 100%;

    .modal-bg {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 101;
      overflow-y: hidden;
      height: 100%;
      width: 100%;
      background-color: $BLACK-LIGHT;
    }

    .modal-container {
      z-index: 102;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 95%;
      max-width: 500px;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background-color: pink;

      .modal-content {
        position: relative;
        background-color: $WHITE;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
        max-width: 500px;
        margin: auto;

        header {
          position: relative;

          .control-btn {
            margin: 0;
          }
        }
      }
    }
  }

  .modal-enter-from {
    opacity: 0;
  }
  .modal-enter-to {
    opacity: 1;
  }
  .modal-enter-active {
    transition: all 0.25s ease-out;
  }
  
  .modal-leave-from {
    opacity: 1;
  }
  .modal-leave-to {
    opacity: 0;
  }
  .modal-leave-active {
    transition: all 0.15s ease-in;
  }
</style>