<template>
  <Simulate 
    :constant="constant" 
    :coinsMinted="coinStore.getCoinsMinted"
    :totalCollateralRequired="coinStore.getTotalCollateral"
    :activeNetwork="networkStore.getActiveNetwork"
    :chartsData="chartStore.getChartsData"
    :configs="configurationStore.getSystemConfigsByName(networkStore.getActiveNetwork.name)"
    :appSpecs="configurationStore.getApplicationSpecifications"
    @nextScreen="nextPrimaryScreen($event)"
    @modalScreen="showModalScreen($event)"
    @updateChartGradient="updateChartGradient($event)"
    @updateChartSettings="updateChartSettings($event)"
    @updateModifiedNetworkPrice="updateModifiedNetworkPrice($event)"
    @throttleAllMaxMintQuantities="throttleAllMaxMintQuantities(event)"
    @throttleAllMaxMintPrices="throttleAllMaxMintPrices(event)"
    @throttleAllMinMintPrices="throttleAllMinMintPrices(event)"
    @updateNetworkCollateralPercentage="updateNetworkCollateralPercentage($event)"
  />
</template>
<script setup>
  import { ref, inject, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { useScreenManagementStore } from '../stores/ScreenManagementStore.js'
  import { useNetworkStore } from '../stores/NetworkStore.js'
  import { useCoinStore } from '../stores/CoinStore.js'
  import { useChartStore } from '../stores/ChartStore.js'
  import { useConfigurationStore } from '../stores/ConfigurationStore.js'

  import Simulate from '../components/Simulate/Simulate.vue'

  const constant = inject('constant')
  const router = useRouter()

  const screenManager = useScreenManagementStore()
  const coinStore = useCoinStore()
  const networkStore = useNetworkStore()
  const chartStore = useChartStore()
  const configurationStore = useConfigurationStore()

  onMounted(() =>{
    scrollSmoothToTop()
  })

  const nextPrimaryScreen = (event) => {
    screenManager.deactivateScreensSubNav()
    router.push({path: `/${constant.MINTING}`})
  }
  const showModalScreen = (event) => {
    screenManager.setActiveScreenModal(event)
  }

  const deactivateAccordions = (event = null) => {
    screenManager.deactivateScreensAccordions()
  }
  const activateAccordion = (event) => {
    screenManager.setActiveAccordionScreen(event)
  }
  const deactivateSubNav = (event) => {
    screenManager.deactivateScreensSubNav()
  }
  const activateScreenSubNav = (event) => {
    screenManager.setActiveSubNavScreen(event)
  }

  const updateChartGradient = (event) => {
    chartStore.setChartGradient(event)
  }
  const updateChartSettings = (event) => {
    chartStore.setChartSettings(event)
  }

  const updateNetworkCollateralPercentage = (event) => {
    networkStore.updateNetworkCollateralPercentage(event)
  }
  const updateModifiedNetworkPrice = (event) => {
    networkStore.setModifiedPrice(event)
    configurationStore.setLocalStorage(constant.LOCAL_STORAGE_NETWORK_KEY, networkStore.getActiveNetwork.id)
    configurationStore.setPresistedConfigsData(getPersistedAppData())
  }

  const throttleAllMaxMintQuantities = (event) => {
    coinStore.throttleAllMaxMintQuantities()
  }
  const throttleAllMaxMintPrices = (event) => {
    coinStore.throttleMaxMintPrice()
  }

  const throttleAllMinMintPrices = (event) => {
    coinStore.throttleMinMintPrice()
  }

  const getPersistedAppData = () => {
    let data = persistedDataObject()
    
    let properties = ''
    properties += networkStore.getQueryParams()
    properties += coinStore.getQueryParams()
    properties += configurationStore.getQueryParams(networkStore.getActiveNetwork.name)

    networkStore.getActiveNetwork.name === constant.FLARE_NETWORK ?
      data[1].val = properties :
      data[0].val = properties
    return data
  }

  const persistedDataObject = () => {
    const data = configurationStore.getLocalStorage(constant.LOCAL_STORAGE_KEY)
    return [
      {
        id: 1,
        val: data && data[0].val ? data[0].val : null
      },
      {
        id: 2,
        val: data && data[1].val ? data[1].val : null
      },
    ]
  }

  const scrollSmoothToTop = () => {
    (function smoothscroll(){
        let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
        let newPosition = 0
        if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll)
          newPosition = currentScroll - (currentScroll / 5)
          window.scrollTo(0, newPosition)
        }
    })()
  }
</script>