<template>
  <div class="container">
    <ActiveNetworkLogo
      :constant="constant"
      :activeNetwork="activeNetwork"
      :classes="'logo'"
      :flrSize="flrSize"
      :sgbSize="sgbSize"
      :color="constant.GREY_XXLIGHT"
      :stroke="true"
    />
    <div 
      class="total-supply flex"
      :class="{
        'color-black': hasCollateral,
        'color-grey': collateralRequired === 0
      }"
    >
      <h4>Circ. Supply</h4>
      <h3>{{ currencyTruncator(tradableSupply, 3) }}</h3>
      <h4>${{ currencyTruncator(marketcap, 3) }}</h4>
    </div>
  </div>
</template>
<script setup>
  import { ref, computed } from 'vue'
  import ActiveNetworkLogo from '../_icons/Network/ActiveNetworkLogo.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 28
    },
    sgbSize: {
      type: Number,
      default: 5.0
    },
    flrSize: {
      type: Number,
      default: 8.0
    },
    configs: {
      type: Object,
      default: () => {}
    },
    totalCollateralRequired: {
      type: Number,
      default: 0
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const tradableSupply = computed(() => {
    return props.activeNetwork.circulatingSupply - props.configs.topHoldersTotalCoinExclusion
  })

  const marketcap = computed(() => {
    return props.activeNetwork.circulatingSupply * props.activeNetwork.price
  })

  const collateralRequired = computed(() => {
    return props.totalCollateralRequired * collateralAllocationCoinPercentage.value
  })

  const collateralAllocationCoinPercentage = computed(() => {
    if(props.collateralFAssetIsActive) {
      return props.networkCollateralAllocation[2].set
    }
    return props.networkCollateralAllocation[1].set
  })

  const hasCollateral = computed(() => {
    return collateralRequired.value > 0
  })

  const currencyFormatter = (value, decimalCount) => {
    return value.toFixed(decimalCount).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  }
  
  const currencyTruncator = (value, decimalCount = 1) => {
    let newValue = parseInt(value)
    let len = newValue.toString().length
    
    if(len >= 7 && len < 10)
      return newValue = `${currencyFormatter(newValue/1000000, decimalCount)}M`
    else if(len >= 10) 
      return newValue=`${currencyFormatter(newValue/1000000000, decimalCount)}B`
    return newValue = currencyFormatter(newValue)
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';

  .container {
    position: absolute;
    flex-direction: column;
    margin: auto;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;

    .logo {
      position: absolute;
    }

    .total-supply, h4 {
      font-size: $FONT-DEFAULT-SIZE;
      font-weight: 600;
    }

    .total-supply {
      z-index: 1;
      
      &.flex {
        flex-direction: column;

        .logo {
          margin-bottom: 5px;
        }
        
        h3 {
          font-size: $FONT-XLARGE;
          line-height: $FONT-SMALL;
          font-weight: 700;
        }

        h4 {
          &:last-child {
            font-size: $FONT-XXSMALL;
          }
        }
      }
    }
  }
</style>