<template>
  <InputRangeSettings 
    v-if="!setting.hide"
    :constant="constant"
    :setting="setting"
    :networkName="activeNetwork.name"
    @modalScreen="modalScreen($event)"
    @updateSettingsConfiguration="updateSettingsConfiguration($event)"
  />
</template>
<script setup>
  import InputRangeSettings from '../../../_generics/InputRangeSettings.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    setting: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
  })

  const constant = props.constant
  const emit = defineEmits([
    'modalScreen',
    'updateSettingsConfiguration'
  ])

  const updateSettingsConfiguration = (event) => {
    emit('updateSettingsConfiguration', event)
  }

  const modalScreen = (event) => {
    emit('modalScreen', event)
  }
</script>
<style lang="scss" scoped>
  @import '../../../../assets/scss/_variables.scss';
</style>