<template>
  <div
    class="container"
  >
    <div class="flex">
      <Rocket />
      <h2>Dream bigger!</h2>
    </div>
    <div>
      <p
        v-if="activeNetwork.name === constant.FLARE_NETWORK"
      >
        It's going to take more than <span class="txt-bold">$500&nbsp;Million&nbsp;dollars</span> worth of {{ activeNetwork.ticker }} to move the needle much. Where we're headed, we need to dream&nbsp;bigger!
      </p>
      <p
        v-else
      >
      It's going to take more than <span class="txt-bold">$100&nbsp;Million&nbsp;dollars</span> worth of {{ activeNetwork.ticker }} to move the needle much. Where we're headed, we need to dream&nbsp;bigger!
      </p>
      <Button 
        class="retry-btn" 
        :eventName="constant.MINTING"
        @clicked="clicked($event)"
      >
        Retry?
      </Button>
    </div>
  
  </div>
</template>
<script setup>
  import Rocket from '../_icons/Rocket.vue'
  import Button from '../_generics/Button.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    tvl: {
      type: Number,
      default: 0
    },
    minCollateral: {
      type: Number,
      default: 0
    }
  })

  const constant = props.constant

  const emit = defineEmits([
    'nextScreen'
  ])

  const clicked = (event) => {
    emit('nextScreen', event)
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';
  
  .container {
    position: relative;
    height: 100%;
    width: 100%;
    flex-direction: column;
    
    .flex {
      width: 100%;
      margin-top: 10px;
      justify-content: center;

      h2 {
        color: $BLACK;
        font-weight: 600;
        margin:  0 0 0 10px;
      }
    }
    p {
      margin: 20px 10px;
      font-size: $FONT-SMALL;
      color: $BLACK;
    }

    .retry-btn {
      background-color: $PURPLE;
      padding: 10px;
      border-radius: 5px;
      border: thin solid $GREY-LIGHT;
      min-width: 150px;
      margin: 10px 0 30px 0;
      color: $WHITE;
      font-size: $FONT-SMALL;
    }
  }
</style>