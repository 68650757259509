<template>
  <div id="simulate"
    class="page"  
  >

    <div class="simulate-tools">
      <div class="simulate-charts">
        <div
          v-if="chartsData[0].target === 'simulate'"
          class="container"
        >
          <SimulateChart
            class="static-chart"
            :constant="constant"
            :configs="configs"
            :chartData="chartsData[0]"
            :networkCollateralAllocation="networkCollateralAllocation"
          />
          <SimulateChart
            :constant="constant"
            :configs="configs"
            :chartData="chartsData[1]"
            :totalCollateralRequired="totalCollateralRequired"
            :activeNetwork="activeNetwork"
            :collateralFAssetIsActive="collateralFAssetIsActive"
            :networkCollateralAllocation="networkCollateralAllocation"
            @updateChartGradient="updateChartGradient($event)"
            @updateChartSettings="updateChartSettings($event)"
          />
        </div>
        <SimulateRequiredCollateralTxt
          :constant="constant"
          :configs="configs"
          :activeNetwork="activeNetwork"
          :totalCollateralRequired="totalCollateralRequired"
          :collateralFAssetIsActive="collateralFAssetIsActive"
          :networkCollateralAllocation="networkCollateralAllocation"
          @modalScreen="modalScreen($event)"
        />
      </div>

      <SimulatePriceController 
        :constant="constant"
        :chartData="chartsData[1]"
        :configs="configs"
        :activeNetwork="activeNetwork"
        :totalCollateralRequired="totalCollateralRequired"
        :collateralFAssetIsActive="collateralFAssetIsActive"
        :networkCollateralAllocation="networkCollateralAllocation"
        @updateChartSettings="updateChartSettings($event)"
        @updateModifiedNetworkPrice="updateModifiedNetworkPrice($event)"
        @updateNetworkCollateralPercentage="updateNetworkCollateralPercentage($event)"
      />
    </div>

    <SimulateFassets
      :constant="constant"
      :configs="configs"
      :appSpecs="appSpecs"
      :activeNetwork="activeNetwork"
      :coinsMinted="coinsMinted" 
      :eventName="constant.MINTING"
      :totalCollateralRequired="totalCollateralRequired"
      :collateralFAssetIsActive="collateralFAssetIsActive"
      :networkCollateralAllocation="networkCollateralAllocation"
      @clicked="clicked($event)" 
    />

    <AppFooter
      :classes="classes"
      :appSpecs="appSpecs"
    />

  </div>
</template>
<script setup>
  import { computed, watch } from 'vue'
  import SimulateChart from './SimulateChart.vue'
  import SimulateRequiredCollateralTxt from './SimulateChartRequiredCollateralTxt.vue'
  import SimulatePriceController from './SimulatePriceController.vue'
  import SimulateFassets from './SimulateFassets.vue'
  import AppFooter from '../App/AppFooter/AppFooter'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    coinsMinted: {
      type: Array,
      default: () => {}
    },
    totalCollateralRequired: {
      type: Number,
      default: 0
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    chartsData: {
      type: Array,
      default: () => []
    },
    configs: {
      type: Object,
      default: () => {}
    },
    appSpecs: {
      type: Object,
      default: () => {}
    },
  })

  const constant = props.constant
  const emit = defineEmits([
    'nextScreen',
    'modalScreen',
    'updateChartGradient',
    'updateChartSettings',
    'updateModifiedNetworkPrice',
    'updateNetworkCollateralPercentage',
    'throttleAllMaxMintQuantities',
    'throttleAllMaxMintPrices',
    'throttleAllMinMintPrices'
  ])

  const classes = computed(() => {
    return {
      'footer-shim' :true
    }
  })

  const collateralFAssetIsActive = computed(() => {
    return props.activeNetwork.collateralManagement[1].isActive
  })

  const networkCollateralAllocation = computed(() => {
    if(collateralFAssetIsActive.value) {
      return props.activeNetwork.collateralManagement[1].allocation
    }
    return props.activeNetwork.collateralManagement[0].allocation
  })
  
  const clicked = (event) => {
    emit('nextScreen', event)
  }

  const modalScreen = (event) => {
    emit('modalScreen', event)
  }

  const updateChartGradient = (event) => {
    emit('updateChartGradient', event)
  }

  const updateChartSettings = (event) => {
    emit('updateChartSettings', event)
  }

  const updateModifiedNetworkPrice = (event) => {
    emit('updateModifiedNetworkPrice', event)
  }

  const updateNetworkCollateralPercentage = (event) => {
    emit('updateNetworkCollateralPercentage', event)
  }

  watch(() => [
      props.configs.settings[constant.SETTINGS_MINT_MAX_SUPPLY_RATE_ID - 1].set,
      props.configs.settings[constant.SETTINGS_MAX_COIN_PRICE_MULTIPLIER_ID - 1].set,
      props.configs.settings[constant.SETTINGS_MIN_COIN_PRICE_MULTIPLIER_ID - 1].set,
  ], (newValue, oldValue) => {
      if(parseFloat(newValue[0]) < parseFloat(oldValue[0])) {
        emit('throttleAllMaxMintQuantities', newValue[0])
      }

      //Max Coin Price - Trottled Down
      if(newValue[1] < oldValue[1]) {
        emit('throttleAllMaxMintPrices', newValue[1])
      }

      //Min Coin Price - Trottled Up
      if(newValue[2] > oldValue[2]) {
        emit('throttleAllMinMintPrices', newValue[2])
      }
  })
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .page {
    padding-top: 0px;
  }

  .simulate-tools {
    background-color: $WHITE;
    position: relative;
    padding-top: $HEADER-HEIGHT;

    @include breakpoint(lg) {
      padding-top: $HEADER-HEIGHT-DESKTOP;
    }
    @include breakpoint(xl) {
      padding-top: $HEADER-HEIGHT-DESKTOP;
    }
    @include breakpoint(xxl) {
      padding-top: $HEADER-HEIGHT-DESKTOP;
    }
    @include breakpoint(xxxl) {
      padding-top: $HEADER-HEIGHT-DESKTOP;
    }

    .simulate-charts {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include breakpoint(lg) {
        flex-direction: row;
      }
      @include breakpoint(xl) {
        flex-direction: row;
      }
      @include breakpoint(xxl) {
        flex-direction: row;
      }
      @include breakpoint(xxxl) {
        flex-direction: row;
      }
    }
  }

  .container {
    margin: 0 0 10px 0;
    height: 300px;

    @include breakpoint(lg) {
      margin: 30px 0 10px 0;
      height: 444px;
    }
    @include breakpoint(xl) {
      margin: 30px 0 10px 0;
      height: 444px;
    }
    @include breakpoint(xxl) {
      margin: 30px 0 10px 0;
      height: 444px;
    }
    @include breakpoint(xxxl) {
      margin: 30px 0 10px 0;
      height: 444px;
    }

    .static-chart {
      position: absolute !important;
    }
  }

  .footer-shim {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 150px;
    padding-right: 10px;
    padding-left: 10px;
  }
</style>